import React, { useState } from 'react';
import zedData from './data/Zed.json';
import './App.css';

/*
Multi-day plan:
- Need to make it look nicer (let's make it dark mode nicer input things)
- Need to import item data
- Need to import champion base stat data
- Need to import champion ability data
*/

const App = () => {
  let postMitigationDamage = 0;

  const [magicResist, setMagicResist] = useState(0);
  const [magicDamage, setMagicDamage] = useState(0);
  const [armor, setArmor] = useState(0);
  const [physicalDamage, setPhysicalDamage] = useState(0);

  const handleMagicResistChange = (event) => {
    setMagicResist(parseInt(event.target.value ? event.target.value : 0));
  };

  const handleMagicDamageChange = (event) => {
    setMagicDamage(parseInt(event.target.value ? event.target.value : 0));
  };

  const handleArmorChange = (event) => {
    setArmor(parseInt(event.target.value ? event.target.value : 0));
  };

  const handlePhysicalDamageChange = (event) => {
    setPhysicalDamage(parseInt(event.target.value ? event.target.value : 0));
  };

  const magicResistFormulaForPositiveMagicResist = 100 / ( 100 + magicResist );
  const magicResistFormulaForNegativeMagicResist = 2 - ( 100 / ( 100 - magicResist ) );

  const armorFormulaForPositiveMagicResist = 100 / ( 100 + armor );
  const armorFormulaForNegativeMagicResist = 2 - ( 100 / ( 100 - armor ) );

  const mrFormula = magicResist >= 0 ? magicResistFormulaForPositiveMagicResist : magicResistFormulaForNegativeMagicResist;
  const armorFormula = armor >= 0 ? armorFormulaForPositiveMagicResist : armorFormulaForNegativeMagicResist;

  postMitigationDamage = magicDamage * mrFormula + physicalDamage * armorFormula;

  console.log(zedData);

  return (
    <div>

      <h1>Damage Calculator</h1>

      <div>
          <label>
            Magic Resist: 
              <input 
                  type="number" 
                  value={magicResist} 
                  onChange={handleMagicResistChange} 
              />
          </label>
      </div>

      <div>
          <label>
            Magic Damage: 
              <input 
                  type="number" 
                  value={magicDamage} 
                  onChange={handleMagicDamageChange} 
              />
          </label>
      </div>

      <div>
          <label>
            Armor: 
              <input 
                  type="number" 
                  value={armor} 
                  onChange={handleArmorChange} 
              />
          </label>
      </div>

      <div>
          <label>
            Physical Damage: 
              <input 
                  type="number" 
                  value={physicalDamage} 
                  onChange={handlePhysicalDamageChange} 
              />
          </label>
      </div>

      <p>The post mitigation damage is: {postMitigationDamage}</p>

    </div>
  );
};

export default App;
